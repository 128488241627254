import React, { useState, useEffect } from 'react';
import { Modal, Form, Image, Button, Segment, TextArea, Icon } from 'semantic-ui-react';
import { assistenciaModalClearAction, assistenciaRespostaClienteAction } from '../../actions/AssistenciaActions';
import StatusDropdown from './StatusDropdown ';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import If from '../../utils/If.js'

import styled from 'styled-components';


const ImageContainer = styled.div`
  display: inline-block;
  margin: 10px; /* Espaçamento entre as imagens */
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.3);
  width: 230px; /* Largura do container */
  height: 230px; /* Aumentado para incluir o texto */
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;

  img {
    max-width: 100%;
    max-height: 100%; /* Altura da imagem */
    object-fit: contain;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px; /* Altura para ícones */
    width: 100%;
  }

  p {
    margin-top: 5px;
    font-size: 1em;
    word-wrap: break-word;
    max-width: 100%;
    height: 40px; /* Espaço reservado para o texto */
    overflow: hidden;
    text-overflow: ellipsis; /* Adiciona reticências caso o texto seja muito longo */
  }
`;

const ContainerWrapper = styled.div`
  margin-bottom: 10px; // Ajuste o espaço entre o título e os containers
`;

const Label = styled.label`
  display: block;
  font-size: 18px; // Ajuste o tamanho do título
  margin-bottom: 10px; // Espaço entre o título e o primeiro container
  text-align: left; // Alinha o título à esquerda (ou use 'center' se quiser centralizar)
  padding-left: 0;
`;

const TextContainer = styled.div`
  display: flex;
  overflow-x: auto;
  
  & > div {
    flex: 0 0 auto;
    width: 400px;
    margin-right: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f44336; /* Vermelho */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #d32f2f; /* Vermelho mais escuro */
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #b71c1c; /* Vermelho mais escuro ao clicar */
  }
`;

const IconWrapper = styled(Icon)`
  margin-right: 8px;
`;

const CharCounter = styled.div`
  position: absolute;
  bottom: 8px;
  right: 10px;
  font-size: 12px;
  color: #666;
`;

// Define o contêiner para os botões no rodapé
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  position: relative;
`;

// Alinha o dropdown de status no canto inferior esquerdo
const StatusDropdownWrapper = styled.div`
  position: absolute;
  bottom: 10px;

`;

// Alinha o botão de fechar no canto inferior direito
const CloseButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const getStatusColor = (status) => {
    switch (status) {
        case 0:
            return '#6c757d';
        case 1:
            return '#20c997';
        case 2:
            return '#fd7e14';
        case 3:
            return '#383845';
        case 4:
            return '#28a745';
        case 5:
            return '#dc3545';
        default:
            return '#6c757d'; // Caso o status não seja 1, 2, 3 ou 4
    }
};

const StatusLabel = styled.span`
  background-color: ${props => getStatusColor(props.status)};
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bold;
`;

function AssistenciaModal({ modalOpen, modal, handleModalClose, assistenciaRespostaClienteAction, userid, isLoadginSave }) {


    const [textareaValue, setTextareaValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(modal?.DataSource1?.[0].status);
    const [saveError, setSaveError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({}); // Para definir o conteúdo do modal


    useEffect(() => {
        if (modal?.DataSource1?.length > 0) {
            setSelectedStatus(modal.DataSource1[0].status);
        }
    }, [modal]);
    const maxLength = 4000;

    const createImageURL = (buffer) => {
        const blob = new Blob([new Uint8Array(buffer)], { type: 'image/jpeg' }); // Ajuste o tipo de imagem conforme necessário
        return URL.createObjectURL(blob);
    };

    const handleTextClick = (text) => {
        setTextareaValue((prevValue) => {
            // Verifica se a adição do novo texto ultrapassará o limite
            if (prevValue.length + text.length <= maxLength) {
                let datareturn = prevValue ? prevValue + '\n' + text : text
                return datareturn
            } else {
                // Se ultrapassar, trunca o texto para que o limite não seja excedido
                const remainingChars = maxLength - prevValue.length;
                return prevValue + text.substring(0, remainingChars);
            }
        });
    };

    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
    };

    const handleClearText = () => {
        setTextareaValue('');
    }

    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus);
        // Aqui você pode adicionar lógica para salvar a alteração do status
    };

    const handleSave = () => {
        console.log('selectedStatus:', selectedStatus);
        console.log('status padrao:', modal?.DataSource1?.[0]?.status);

        const statusNaoAlterado = selectedStatus === modal?.DataSource1?.[0]?.status;
        const textoVazio = !textareaValue || textareaValue.trim() === '';

        const statusText = selectedStatus === 0 ? 'Aguardando Análise' :
            selectedStatus === 1 ? 'Em análise' :
                selectedStatus === 2 ? 'Aguardando Cliente' :
                    selectedStatus === 3 ? 'Aguardando Técnico' :
                        selectedStatus === 4 ? 'Procedente' :
                            selectedStatus === 5 ? 'Improcedente' : '';

        if (statusNaoAlterado && textoVazio) {
            setSaveError(true);
            setModalContent({
                title: 'Erro',
                message: 'Nenhuma alteração feita.',
                showConfirmButton: false
            });
            setOpenModal(true);
            return;
        }

        let message = '';
        if (!statusNaoAlterado && !textoVazio) {
            message = `Status alterado para: <b>${statusText}</b> <br/><br/>
                       Texto que será enviado ao cliente: <b>${textareaValue}</b>`;
        } else if (!statusNaoAlterado) {
            message = `Status alterado para: <b>${statusText}</b>`;
        } else if (!textoVazio) {
            message = `Texto que será enviado ao cliente: <b>${textareaValue}</b>`;
        }

        setSaveError(false);
        setModalContent({
            title: 'Confirme as Alterações',
            message: message,
            showConfirmButton: true
        });
        setOpenModal(true);
    };

    const confirmSave = () => {
        const data = {
            selectedStatus: selectedStatus,
            textareaValue: textareaValue,
            userid: parseInt(userid),
            assistenciaid: modal?.DataSource1?.[0]?.assistencia_id
        };
        assistenciaRespostaClienteAction(data).then(() => {
            setTextareaValue('');
            handleModalClose();
        });
        setOpenModal(false);
    };



    return (
        <>
            <div>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    size="small"
                >
                    <Modal.Header>{modalContent.title}</Modal.Header>
                    <Modal.Content>
                        <p dangerouslySetInnerHTML={{ __html: modalContent.message }} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenModal(false)}>Fechar</Button>
                        {modalContent.showConfirmButton && (
                            <Button onClick={confirmSave} positive>Confirmar</Button>
                        )}
                    </Modal.Actions>
                </Modal>
            </div>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                size='large'
                scrolling
            > {modal?.DataSource1?.map((item) => {
                return (
                    <>
                        <Modal.Header>
                            <Modal.Header>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div>
                                        <span>Protocolo: {item.protocolo}</span>
                                        <br />
                                        <span style={{ fontSize: '16px', color: '#666' }}>
                                            Nº Nota: {item.nunota}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '16px' }}>
                                            <StatusLabel status={item.status}>
                                                {item.status === 0 ? 'Aguardando Análise' :
                                                    item.status === 1 ? 'Em análise' :
                                                        item.status === 2 ? 'Aguardando Cliente' :
                                                            item.status === 3 ? 'Aguardando Técnico' :
                                                                item.status === 4 ? 'Procedente' :
                                                                    item.status === 5 ? 'Improcedente' : ''}
                                            </StatusLabel>
                                        </span>
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <span>
                                            Data de Criação: {new Date(item.dtcriacao).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '16px', color: '#666' }}>
                                            Prazo: {new Date(item.prazo).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </span>
                                    </div>
                                </div>
                            </Modal.Header>

                        </Modal.Header>

                        <Modal.Content>

                            <Form>
                                <span style={{ fontSize: '23px' }}>Produto: <b>{item.produto}</b> ({item.fabricante}) </span>
                                <Segment>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nome/Codparc</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Nome'
                                                name='nome'
                                                label={item.nomeparc + ' ' + '(' + item.codparc + ')'}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Telefone</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Telefone'
                                                name='telefone'
                                                label={item.telefone}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Email'
                                                name='email'
                                                label={item.email}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>CPF/CNPJ</label>
                                            <Form.Field
                                                fluid
                                                placeholder='CPF'
                                                name='cpf'
                                                label={item.cpf}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Cidade</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Cidade'
                                                name='cidade'
                                                label={item.cidade}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bairro</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Bairro'
                                                name='bairro'
                                                label={item.bairro}
                                                readOnly
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Endereço</label>
                                            <Form.Field
                                                fluid
                                                placeholder='Endereço'
                                                name='endereco'
                                                label={item.endereco}
                                                readOnly
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Reclamação do cliente:</label>
                                            <Form.Field
                                                fluid
                                                placeholder='reclamação'
                                                name='reclamação'
                                                label={item.observacao}
                                                readOnly
                                                style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                    border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                    padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                    backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                    borderRadius: '5px',      // Arredonda levemente os cantos
                                                }}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <If test={modal?.DataSource1?.[0].status !== 4 && modal?.DataSource1?.[0].status !== 5}>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>Histórico de conversa:</label>
                                                <Form.Field
                                                    fluid
                                                    dangerouslySetInnerHTML={{ __html: modal?.DataSource1?.[0].historico }}
                                                    readOnly
                                                    style={{
                                                        marginTop: '10px',          // Define a altura do campo para melhorar a visualização de longos textos
                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                        padding: '10px',          // Espaçamento interno para melhorar a legibilidade
                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                        whiteSpace: 'pre-wrap',   // Preserva as quebras de linha
                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                    }}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </If>

                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Anexos:</label>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {modal.DataSource2.map((foto, index) => (
                                                        foto && foto.type === 'video/mp4' ? (
                                                            <ImageContainer key={index}>
                                                                <video width="200" height="150" controls>
                                                                    <source src={foto.arquivo} type="video/mp4" />
                                                                    Seu navegador não suporta o elemento de vídeo.
                                                                </video>
                                                                <p>{foto.nomearq}</p>
                                                            </ImageContainer>
                                                        ) : foto.type === 'application/pdf' ? (
                                                            <ImageContainer key={index}>
                                                                <a src={foto.arquivo} href={foto.arquivo} target="_blank" rel="noopener noreferrer" className="icon-container">
                                                                    <Icon name="file pdf outline" size="huge" color="red" />
                                                                </a>
                                                                <p>{foto.nomearq}</p>
                                                            </ImageContainer>
                                                        ) : ''
                                                    ))}
                                                </div>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {modal.DataSource2.map((foto, index) => (
                                                        foto && foto.type !== 'application/pdf' && foto.type !== 'video/mp4' && (
                                                            <ImageContainer key={index}>
                                                                <a href={foto.arquivo} target="_blank" rel="noopener noreferrer">
                                                                    <img src={foto.arquivo} alt={foto.nomearq} />
                                                                </a>
                                                                <p>{foto.nomearq}</p>
                                                            </ImageContainer>
                                                        )
                                                    ))}
                                                </div>
                                            </div>
                                        </Form.Field>


                                    </Form.Group>
                                </Segment>
                                <If test={modal?.DataSource1?.[0].status !== 4}>
                                    <If test={modal?.DataSource1?.[0].status !== 5}>
                                        <ContainerWrapper>
                                            <Label>Templates:</Label>
                                            <TextContainer>
                                                <div>
                                                    <Label><b>Negativa de defeito:</b></Label>
                                                    <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nAgradecemos por nos contatar. Gostaríamos de esclarecer que a garantia do produto cobre defeitos de fabricação. Com base nas informações fornecidas, não identificamos indícios de um defeito de fabricação. No entanto, se você observar qualquer desacordo com o material conforme relatado, recomendamos que solicite a troca ou devolução do produto para que possamos resolver a questão adequadamente.\n\n Atenciosamente,`.replace(/<br\/?>/g, '\n'))}>
                                                        Prezado(a) {item.nomeparc},<br /><br />
                                                        Agradecemos por nos contatar. Gostaríamos de esclarecer que a garantia do produto cobre defeitos de fabricação. Com base nas informações fornecidas, não identificamos indícios de um defeito de fabricação. No entanto, se você observar qualquer desacordo com o material conforme relatado, recomendamos que solicite a troca ou devolução do produto para que possamos resolver a questão adequadamente.<br /><br />
                                                        Atenciosamente,<br />
                                                    </div>
                                                </div>

                                                <div>
                                                    <Label><b>Negativa de coleta:</b></Label>
                                                    <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nApós a realização da instalação, confirmamos que o material está em conformidade com as especificações e apto para uso. Portanto, não é possível proceder com a coleta e troca do produto neste momento.\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                        Prezado(a) {item.nomeparc},<br /><br />
                                                        Após a realização da instalação, confirmamos que o material está em conformidade com as especificações e apto para uso. Portanto, não é possível proceder com a coleta e troca do produto neste momento.<br /><br />
                                                        Atenciosamente,<br />
                                                    </div>
                                                </div>

                                                <div>
                                                    <Label><b>Solicitação de preenchimento de informações:</b></Label>
                                                    <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nPara darmos continuidade ao atendimento, por favor, preencha a planilha em anexo e nos envie de volta com as informações solicitadas.\n\nAtenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                        Prezado(a) {item.nomeparc},<br /><br />
                                                        Para darmos continuidade ao atendimento, por favor, preencha a planilha em anexo e nos envie de volta com as informações solicitadas.<br /><br />
                                                        Atenciosamente,
                                                    </div>
                                                </div>

                                                <div>
                                                    <Label><b>Confirmação de vistoria não recebida:</b></Label>
                                                    <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nInformamos que, como não recebemos a confirmação para a vistoria conforme solicitado no e-mail anterior, estamos encerrando o atendimento referente a este chamado.\n\nSe precisar de assistência adicional ou desejar reabrir o chamado no futuro, por favor, entre em contato conosco.\n\n Atenciosamente,  `.replace(/<br\/?>/g, '\n'))}>
                                                        Prezado(a) {item.nomeparc},<br /><br />
                                                        Informamos que, como não recebemos a confirmação para a vistoria conforme solicitado no e-mail anterior, estamos encerrando o atendimento referente a este chamado.<br /><br />
                                                        Se precisar de assistência adicional ou desejar reabrir o chamado no futuro, por favor, entre em contato conosco.<br /><br />
                                                        Atenciosamente,
                                                    </div>
                                                </div>
                                            </TextContainer>
                                        </ContainerWrapper>

                                        <Form.Group widths='equal'>
                                            <Form.Field control={TextArea} label='Resposta:'
                                                placeholder='Digite ou selecione um Template para responder o cliente...'
                                                onChange={handleTextareaChange}
                                                value={textareaValue}
                                                maxLength={4000}
                                                rows='9'
                                            />
                                            <CharCounter>
                                                {textareaValue.length}/{maxLength}
                                            </CharCounter>
                                        </Form.Group>
                                        <ClearButton onClick={handleClearText}>
                                            <IconWrapper name='trash' /> Limpar
                                        </ClearButton>
                                    </If>
                                </If>
                                <If test={modal?.DataSource1?.[0].status === 4 || modal?.DataSource1?.[0].status === 5}>
                                    <Form.Group>
                                        <Form.Field>
                                            <label>Histórico de conversa:</label>
                                            <Form.Field
                                                fluid
                                                dangerouslySetInnerHTML={{ __html: modal?.DataSource1?.[0].historico }}
                                                readOnly
                                                style={{
                                                    width: '680px',          // Define a altura do campo para melhorar a visualização de longos textos
                                                    border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                    padding: '10px',          // Espaçamento interno para melhorar a legibilidade
                                                    backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                    whiteSpace: 'pre-wrap',   // Preserva as quebras de linha
                                                    borderRadius: '5px',      // Arredonda levemente os cantos
                                                }}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Histórico de status:</label>
                                            <Form.Field
                                                fluid
                                                dangerouslySetInnerHTML={{ __html: modal?.DataSource1?.[0].historico_status }}
                                                readOnly
                                                style={{
                                                    border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                    padding: '10px',          // Espaçamento interno para melhorar a legibilidade
                                                    backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                    whiteSpace: 'pre-wrap',   // Preserva as quebras de linha
                                                    borderRadius: '5px',      // Arredonda levemente os cantos
                                                }}
                                            />
                                        </Form.Field>
                                    </Form.Group>

                                </If>
                            </Form>
                        </Modal.Content >
                    </>
                );
            })}
                <Modal.Content>
                    <FooterContainer>
                        <StatusDropdownWrapper>
                            <Form.Field>
                                <label>Status:</label>
                                <StatusDropdown value={selectedStatus} onChange={handleStatusChange} status={modal?.DataSource1?.[0]?.status} />
                            </Form.Field>
                        </StatusDropdownWrapper>
                        <CloseButtonWrapper>
                            <Button color='red' onClick={() => {
                                handleClearText();
                                handleModalClose();
                            }}
                            >
                                <Icon name='close' /> Fechar
                            </Button>
                            <If test={modal?.DataSource1?.[0].status !== 4}>
                                <If test={modal?.DataSource1?.[0].status !== 5}>
                                    <Button color='green' onClick={handleSave} loading={isLoadginSave}>
                                        <Icon name='checkmark' /> Salvar
                                    </Button>
                                </If>
                            </If>
                        </CloseButtonWrapper>
                    </FooterContainer>
                </Modal.Content>
            </Modal >
        </>
    );
}

const mapStateToProps = (state) => ({
    userid: state.LoginReducer.userid,
    isLoadginSave: state.AssistenciaReducer.isLoadginSave
})


export default withRouter(connect(mapStateToProps, {
    assistenciaModalClearAction,
    assistenciaRespostaClienteAction,
}
)(AssistenciaModal));