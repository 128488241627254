import {
    ASSISTENCIA_LIST,
    ASSISTENCIA_CARD_LIST,
    ASSISTENCIA_LIST_LOADING,
    ASSISTENCIA_MODAL,
    ASSISTENCIA_MODAL_LOADING,
    ASSISTENCIA_LIST_FILTRO,
    ASSISTENCIA_GRID_LOADING,
    ASSISTENCIA_LOADING_SAVE
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    assistencias: [],
    assistenciascard: [],
    assistenciamodal: [],
    isloading: true,
    isloadingGrid: true,
    isloadingModal: false,
    isFiltro: false,
    isLoadginSave: false
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case ASSISTENCIA_LIST:
            return { ...state, assistencias: action.payload }
        case ASSISTENCIA_CARD_LIST:
            return { ...state, assistenciascard: action.payload }
        case ASSISTENCIA_LIST_LOADING:
            return { ...state, isloading: action.payload }

        case ASSISTENCIA_LOADING_SAVE:
            return { ...state, isLoadginSave: action.payload }


        case ASSISTENCIA_LIST_FILTRO:
            return { ...state, isFiltro: action.payload }
        case ASSISTENCIA_GRID_LOADING:
            return { ...state, isloadingGrid: action.payload }

        case ASSISTENCIA_MODAL:
            return { ...state, assistenciamodal: action.payload }
        case ASSISTENCIA_MODAL_LOADING:
            return { ...state, isloadingModal: action.payload }

        default:
            return state;
    }
}