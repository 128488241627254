import React, { useEffect } from 'react';
import { Breadcrumb, Grid, Icon, Menu, Tab, TabPane } from 'semantic-ui-react';
import styled from 'styled-components';
import Visitas from './Visitas';
import KpiChart from './KpiChart';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { loginRedirectAction } from '../../actions/LoginActions';
import { kpivisitasListAction } from '../../actions/KpiVisitasActions';

function KpiVisitas(props) {

    const { isloading } = props;

    const StyledTab = styled(Tab)`
&&& {

  .ui.tabular.menu .item{
      font-size:10px !important;
      height:10px !important;
  }
  .ui.menu{
    min-height: 0.0em !important;
  }

 }`

    let panes = [

        {
            menuItem: <Menu.Item key='TAB1'><Icon name='users' />Visitas</Menu.Item>,
            render: () => (
                <TabPane loading={isloading}>
                    <Visitas />
                </TabPane>
            )
        },
        {
            menuItem: <Menu.Item key='TAB2'><Icon name='area graph' />Gráfico</Menu.Item>,
            render: () => (
                <KpiChart />
            )
        },
    ]

    const { loginRedirectAction } = props;

    // console.log('IS AUTHENTICATED', isAuthenticated);[]

    const { isAuthenticated } = props;

    useEffect(() => {

        if (isAuthenticated) {
            kpivisitasListAction();
        }
        if (!isAuthenticated) {
            // sessionStorage.setItem("abriutarefa", 0);
            loginRedirectAction(`/KpiVisitas`)
        }
        const { menu, history } = props;
        if (menu.filter(m => m.menuid === 30 && m.status === 1).length === 0) { history.push(`/Home`) }
    }, []);


    return (
        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>KPI Visitas</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>

            <Grid columns={1} doubling>
                <Grid.Column>
                    <StyledTab panes={panes} />
                </Grid.Column>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => ({
    isloading: state.KpiVisitasReducer.isloading,
    isAuthenticated: state.LoginReducer.isAuthenticated,
    menu: state.LoginReducer.menu

})


export default withRouter(connect(mapStateToProps, {
    loginRedirectAction
}
)(KpiVisitas));
