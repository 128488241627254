import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import If from '../../utils/If.js'



const StatusDropdown = ({ value, onChange, status }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return '#6c757d';
            case 1:
                return '#20c997';
            case 2:
                return '#fd7e14';
            case 3:
                return '#383845';
            case 4:
                return '#28a745';
            case 5:
                return '#dc3545';
            default:
                return '#6c757d'; // Caso o status não seja 1, 2, 3 ou 4
        }
    };

    const options = [
        { key: 0, text: 'Aguardando Análise', value: 0 },
        { key: 1, text: 'Em Análise', value: 1 },
        { key: 2, text: 'Aguardando Cliente', value: 2 },
        { key: 3, text: 'Aguardando Técnico', value: 3 },
        { key: 4, text: 'Procedente', value: 4 },
        { key: 5, text: 'Improcedente', value: 5 },
    ];

    // Filtra a lista de opções para remover a já selecionada
    const filteredOptions = options.filter(option => option.value !== selectedValue);

    const handleOptionClick = (option) => {
        setSelectedValue(option.value);
        onChange(option.value);
        setIsOpen(false);
    };
    console.log(status)
    const isDisabled = status === 4 || status === 5;

    return (
        <DropdownContainer>
            <DropdownButton
                onClick={() => !isDisabled && setIsOpen(!isOpen)}
                disabled={isDisabled}
            >
                <LabelWithColor color={getStatusColor(selectedValue)}>
                    {options.find(option => option.value === selectedValue)?.text || 'Selecione o Status'}
                </LabelWithColor>
                <DropdownIcon name={isOpen ? 'angle up' : 'angle down'} />
            </DropdownButton>
            {isOpen && !isDisabled && (
                <DropdownMenu>
                    {filteredOptions.map(option => (
                        <DropdownMenuItem key={option.key} onClick={() => handleOptionClick(option)}>
                            <LabelWithColor color={getStatusColor(option.value)}>
                                {option.text}
                            </LabelWithColor>
                        </DropdownMenuItem>
                    ))}
                </DropdownMenu>
            )}
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div`
    position: relative;
    width: 180px;
`;

const DropdownButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 180px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
`;

const DropdownIcon = styled(Icon)`
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; // Prevents click events on the icon
`;

const DropdownMenu = styled.div`
    position: absolute;
    bottom: 100%; /* Posiciona o menu acima do botão */
    left: 0;
    background-color: #fff;
    width: 180px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

const DropdownMenuItem = styled.div`
    padding: 10px;
    cursor: pointer;
    width: 100%;
    &:hover {
        background-color: #f1f1f1;
        width: 100%;
    }
`;

const LabelWithColor = styled.span`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #fff;
    width: 100%;
    background-color: ${props => props.color};
    border-radius: 3px;
`;

export default StatusDropdown;
